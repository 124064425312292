import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/Layout"
import StyledLink from "@components/Link"
import Seo from "@components/Seo"

const CoursesPage = ({ data }) => {
  const { courses, pageMeta } = data

  return (
    <Layout>
      <Seo yoastSeo={pageMeta?.seo} />

      <section className="bg-slate-100 dark:bg-slate-800 py-20">
        <div className="container--boxed">
          <h1 className="h1 text-center">Courses</h1>
        </div>
      </section>

      <section className="mt-20">
        <div className="container--boxed-sm">
          {courses.edges.map(({ node }, index) => (
            <>
              <article key={node.id} className="relative">
                <aside className="absolute right-full text-4xl text-slate-200 pr-4">
                  {index + 1}
                </aside>
                <div className="">
                  <h2 className="text-xl font-semibold dark:text-slate-200">
                    <a
                      href={node?.courseCustomFields?.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:underline underline-offset-4"
                    >
                      {node.title}
                    </a>
                    {node?.courseCustomFields?.highlightText && (
                      <span className="text-slate-400 font-normal text-base ml-2">
                        {node.courseCustomFields.highlightText}
                      </span>
                    )}
                  </h2>
                </div>
                <div
                  className="prose dark:prose-invert mt-4"
                  dangerouslySetInnerHTML={{ __html: node.content }}
                ></div>
                <div className="mt-6">
                  <StyledLink
                    variant="arrow"
                    url={{
                      type: "external",
                      href: node?.courseCustomFields?.link,
                    }}
                  >
                    Learn more
                  </StyledLink>
                </div>
              </article>
              <hr className="pb-10 mt-10 last:hidden dark:border-slate-700" />
            </>
          ))}
        </div>
      </section>
    </Layout>
  )
}

export default CoursesPage

export const pageQuery = graphql`
  query {
    courses: allWpCourse(
      limit: 100
      sort: { fields: [date], order: DESC }
      filter: { status: { eq: "publish" } }
    ) {
      edges {
        node {
          ...WpCourseFields
        }
      }
    }

    pageMeta: wpPage(slug: { eq: "courses" }) {
      ...WpPageFields
    }
  }
`
